import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { Provider } from 'react-redux';
import store from 'reduxStore/store';
import './utils/i18nInit'
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// AOS.init();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);