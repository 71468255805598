import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState ,useEffect } from 'react'
import styles from './BeingInControl.module.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Col, Container, Row } from 'react-bootstrap'
import aboutUsImage from 'assets/imgs/aboutUsImage.png'
import {ReactComponent as RightArrow} from 'assets/icons/rightArrow.svg'
import {ReactComponent as LeftArrow} from 'assets/icons/LeftArrow.svg'
import SwiperBeingInControl from 'components/Global/Elements/SwiperBeingInControl/SwiperBeingInControl';
import SectionTitle from 'components/Global/Elements/SectionTitle/SectionTitle';
import ReactPlayer from 'react-player';
import HeaderBgVideo1 from 'assets/videos/HeaderBgVideo1.mp4'
import HeaderBgVideo2 from 'assets/videos/HeaderBgVideo2.mp4'
import HeaderBgVideo3 from 'assets/videos/HeaderBgVideo3.mp4'
import HeaderBgVideo4 from 'assets/videos/HeaderBgVideo4.mp4'
import HeaderBg1 from 'assets/imgs/HeaderBg1.jpg'
import HeaderBg2 from 'assets/imgs/HeaderBg2.jpg'
import HeaderBg3 from 'assets/imgs/HeaderBg3.jpg'
import HeaderBg4 from 'assets/imgs/HeaderBg4.jpg'
import VideoModal from 'components/Global/Elements/VideoModal/VideoModal';

type VideoType = {
  image:string,
  video:string,
  time:string,
  title:string,
}
function BeingInControl() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [activeVideoSrc ,setActiveVideoSrc] =useState<string>(HeaderBgVideo1)
  const [allVideos ,setAllVideos]=useState<VideoType[]>([
    {image:HeaderBg1,video:HeaderBgVideo1,time:'10.00',title:'Being In Control'},
    {image:HeaderBg2,video:HeaderBgVideo2,time:'20.00',title:'Being In Control'},
    {image:HeaderBg3,video:HeaderBgVideo3,time:'15.00',title:'Being In Control'},
    {image:HeaderBg4,video:HeaderBgVideo4,time:'40.00',title:'Being In Control'},
    {image:HeaderBg1,video:HeaderBgVideo1,time:'10.00',title:'Being In Control'},
    {image:HeaderBg2,video:HeaderBgVideo2,time:'20.00',title:'Being In Control'},
    {image:HeaderBg3,video:HeaderBgVideo3,time:'15.00',title:'Being In Control'},
    {image:HeaderBg4,video:HeaderBgVideo4,time:'40.00',title:'Being In Control'},
  ])
  const [technologyVideos ,setTechnologyVideos]=useState<VideoType[]>([
    {image:HeaderBg1,video:HeaderBgVideo1,time:'10.00',title:'Being In Control'},
    {image:HeaderBg2,video:HeaderBgVideo2,time:'20.00',title:'Being In Control'},
    {image:HeaderBg3,video:HeaderBgVideo3,time:'15.00',title:'Being In Control'},
  ])
  const [videoGraphyVideos ,setVideoGraphyVideos]=useState<VideoType[]>([
    {image:HeaderBg1,video:HeaderBgVideo1,time:'10.00',title:'Being In Control'},
    {image:HeaderBg2,video:HeaderBgVideo2,time:'20.00',title:'Being In Control'},
    {image:HeaderBg3,video:HeaderBgVideo3,time:'15.00',title:'Being In Control'},
    {image:HeaderBg4,video:HeaderBgVideo4,time:'40.00',title:'Being In Control'},
  ])
  const [natureVideos ,setNatureVideos]=useState<VideoType[]>([
    {image:HeaderBg1,video:HeaderBgVideo1,time:'10.00',title:'Being In Control'},
    {image:HeaderBg3,video:HeaderBgVideo3,time:'15.00',title:'Being In Control'},
    {image:HeaderBg4,video:HeaderBgVideo4,time:'40.00',title:'Being In Control'},
  ])
  const [activeSwiperSection ,setActiveSwiperSection] =useState('all')
  return (
    <>
      <section className={styles['being-in-control']} id='control'>

        <SectionTitle title={`${t('PORTFOLIO')}`}/>
        <div className={styles['being-in-control__video-wrapper']}>
          <ReactPlayer 
            url={activeVideoSrc} 
            controls={false}
            playing={true}
            loop={true}
            muted ={true}
            width="100%"
            height="100%"
            className={styles['being-in-control__video']}
            config={{
                youtube: {
                    playerVars: { showinfo: 1 }
                },
                facebook: {
                    appId: '12345'
                }
            }}
          />
        </div>
        <div className={styles['being-in-control__content-wrapper']}>
          <Container >
            <ul className={styles['being-in-control__list']}>
              <li className={styles['being-in-control__list-item']}>
                <button 
                className={`${styles['being-in-control__list-button']} ${activeSwiperSection=='all'?styles['being-in-control__list-button--active']:''}`}
                onClick={()=>{setActiveSwiperSection('all')}}>{t('All')}</button>
              </li>
              <li className={styles['being-in-control__list-item']}>
                <button 
                className={`${styles['being-in-control__list-button']} ${activeSwiperSection=='videography'?styles['being-in-control__list-button--active']:''}`}
                onClick={()=>{setActiveSwiperSection('videography')}}>{t('VIDEOGRAPHY')}</button>
              </li>
              <li className={styles['being-in-control__list-item']}>
                <button 
                className={`${styles['being-in-control__list-button']} ${activeSwiperSection=='technology'?styles['being-in-control__list-button--active']:''}`}
                onClick={()=>{setActiveSwiperSection('technology')}}>{t('TECHNOLOGIES')}</button>
              </li>
              <li className={styles['being-in-control__list-item']}>
                <button 
                className={`${styles['being-in-control__list-button']} ${activeSwiperSection=='nature'?styles['being-in-control__list-button--active']:''}`}
                onClick={()=>{setActiveSwiperSection('nature')}}>{t('NATURE')}</button>
              </li>
            </ul>
            <div >
              <h2 className={styles['being-in-control__content-title']}>{t('Being in Your Control')}</h2>
              <p className={styles['being-in-control__content-description']}>{t('Remember that you’re writing this for humans, not for search engines in particular. Or at least that’s what you should be doing. So it helps to be friendly, confident...')}</p>
              <VideoModal/>
              {/* <button className={styles['being-in-control__play-control']}>
                <RightArrow className={styles['being-in-control__play-control-icon']}/>
              </button> */}
            </div>

            {/* all */}
            <div className={`${styles['being-in-control__swiper-wrapper']} ${activeSwiperSection=='all'?styles['being-in-control__swiper-wrapper--active']:''}`}>
              <Swiper
                spaceBetween={10}
                slidesPerView={2}
                // ref={swiperRef}
                loop={true}
                cssMode= {true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                    
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay,  Navigation]}
                className="mySwiper"
              >
                {
                  allVideos &&allVideos?.map((video:VideoType,index:number)=>(
                    <SwiperSlide key={index}>
                      <SwiperBeingInControl 
                      image={video?.image} 
                      time={video?.time} 
                      video={video?.video} 
                      title={video?.title} 
                      setActiveVideoSrc={setActiveVideoSrc}
                      />
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>

            {/* VIDEOGRAPHY */}
            <div className={`${styles['being-in-control__swiper-wrapper']} ${activeSwiperSection=='videography'?styles['being-in-control__swiper-wrapper--active']:''}`}>
              <Swiper
                spaceBetween={30}
                slidesPerView={4}
                // ref={swiperRef}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // breakpoints={{
                //     768: {
                //         slidesPerView: 1.7,
                //         spaceBetween: 15,
                //     },
                    
                // }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay,  Navigation]}
                className="mySwiper"
              >
                {
                  videoGraphyVideos &&videoGraphyVideos?.map((video:VideoType,index:number)=>(
                    <SwiperSlide key={index}>
                      <SwiperBeingInControl 
                      image={video?.image} 
                      time={video?.time} 
                      video={video?.video} 
                      title={video?.title} 
                      setActiveVideoSrc={setActiveVideoSrc}
                      />
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>

            {/* TECHNOLOGIES */}
            <div className={`${styles['being-in-control__swiper-wrapper']} ${activeSwiperSection=='technology'?styles['being-in-control__swiper-wrapper--active']:''}`}>
              <Swiper
                spaceBetween={30}
                slidesPerView={4}
                cssMode= {true}
                // ref={swiperRef}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // breakpoints={{
                //     768: {
                //         slidesPerView: 1.7,
                //         spaceBetween: 15,
                //     },
                    
                // }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay,  Navigation]}
                className="mySwiper"
              >
                {
                  technologyVideos &&technologyVideos?.map((video:VideoType,index:number)=>(
                    <SwiperSlide key={index}>
                      <SwiperBeingInControl 
                      image={video?.image} 
                      time={video?.time} 
                      video={video?.video} 
                      title={video?.title} 
                      setActiveVideoSrc={setActiveVideoSrc}
                      />
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>
            
            {/* NATURE */}
            <div className={`${styles['being-in-control__swiper-wrapper']} ${activeSwiperSection=='nature'?styles['being-in-control__swiper-wrapper--active']:''}`}>
              <Swiper
                spaceBetween={30}
                slidesPerView={4}
                // ref={swiperRef}
                loop={true}
                cssMode= {true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // breakpoints={{
                //     768: {
                //         slidesPerView: 1.7,
                //         spaceBetween: 15,
                //     },
                    
                // }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay,  Navigation]}
                className="mySwiper"
              >
                {
                  natureVideos &&natureVideos?.map((video:VideoType,index:number)=>(
                    <SwiperSlide key={index}>
                      <SwiperBeingInControl 
                      image={video?.image} 
                      time={video?.time} 
                      video={video?.video} 
                      title={video?.title} 
                      setActiveVideoSrc={setActiveVideoSrc}
                      />
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>
          </Container>
        </div>
      </section>
    </>
  )
}

export default BeingInControl