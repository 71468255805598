import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useCallback, useRef, useState ,useEffect} from 'react'
import styles from './Header.module.css'
import { Swiper, SwiperSlide ,SwiperRef } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import SectionTitle from 'components/Global/Elements/SectionTitle/SectionTitle'
import SwiperHeader from 'components/Global/Elements/SwiperHeader/SwiperHeader';
import {ReactComponent as LeftArrowTall} from 'assets/icons/leftArrowTall.svg'
import {ReactComponent as RightArrowTall} from 'assets/icons/rightArrowTall.svg'
import StyledButton from './HeaderStyled';
// import StyledButton from './HeaderStyled.tsx'
import HeaderBgVideo1 from 'assets/videos/HeaderBgVideo1.mp4'
import HeaderBgVideo2 from 'assets/videos/HeaderBgVideo2.mp4'
import HeaderBgVideo3 from 'assets/videos/HeaderBgVideo3.mp4'
import HeaderBgVideo4 from 'assets/videos/HeaderBgVideo4.mp4'
import HeaderBg1 from 'assets/imgs/HeaderBg1.jpg'
import HeaderBg2 from 'assets/imgs/HeaderBg2.jpg'
import HeaderBg3 from 'assets/imgs/HeaderBg3.jpg'
import HeaderBg4 from 'assets/imgs/HeaderBg4.jpg'
type SliderType={
  title:string,
  subTitle:string,
  videoSrc:string,
  bgSrc:string
}
function Header() {
  const {t} =useTranslation()
  const sliderRef = useRef<SwiperRef>(null)
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [activeSlideIndex,setActiveSlideIndex]=useState(0)
  const [slides,setSlides]=useState<SliderType[]>([
    {title:'Very Goog',subTitle:'Featuring',videoSrc:HeaderBgVideo1,bgSrc:HeaderBg1},
    {title:'Very Goog',subTitle:'Featuring',videoSrc:HeaderBgVideo2,bgSrc:HeaderBg2},
    {title:'Very Goog',subTitle:'Featuring',videoSrc:HeaderBgVideo3,bgSrc:HeaderBg3},
    {title:'Very Goog',subTitle:'Featuring',videoSrc:HeaderBgVideo4,bgSrc:HeaderBg4},
  ])
  // title ,subTitle,videoSrc,bgSrc,sliderNumber
  // const [images,setImages]=useState<any>([])
  
  const handlePrev = useCallback(() => {
  if (!sliderRef.current) return;
    sliderRef?.current?.swiper?.slidePrev();
    setActiveSlideIndex(prevVal=>prevVal-1)
  if(sliderRef?.current?.swiper?.isBeginning) {
      setActiveSlideIndex(0)
    }
}, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
      sliderRef.current.swiper.slideNext();
      setActiveSlideIndex(prevVal=>prevVal+1)
    if(sliderRef.current.swiper.isEnd) {
        setActiveSlideIndex(slides?.length-1)
    }
  }, []);

  function changeActiveSlideIndex(index:any){
    setActiveSlideIndex(index)
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideTo(index);;
  }
  function changeActiveSlider(e:any){
    setActiveSlideIndex(sliderRef?.current?.swiper?.activeIndex as number)
  }
  // useEffect(()=>{
    
  //   console.log('dadsdsa',sliderRef?.current?.swiper?.activeIndex)
  //   // setActiveSlideIndex(sliderRef?.current?.swiper?.activeIndex as number)
  // },[sliderRef?.current?.swiper?.activeIndex])
  return (
    <>
      <section className={styles['header']}>
        <SectionTitle title={`${t('Featured Work')}`}/>
        {/* <Container className='h-100'> */}
          {/* <div className={styles['header__video-wrapper']}> */}
          <Swiper
              // onSwiper={changeActiveSlider}
              onSlideChange={(e) => changeActiveSlider(e.realIndex)}
              spaceBetween={0}
              slidesPerView={1}
              ref={sliderRef}
              cssMode= {true}
              // ref={swiperRef}
              // loop={true}
              // autoplay={{
              //     delay: 2500,
              //     disableOnInteraction: false,
              // }}
              // breakpoints={{
              //     768: {
              //         slidesPerView: 1.7,
              //         spaceBetween: 15,
              //     },
              // }}
              pagination={{
                  clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper h-100"
            >
              {
                slides && slides?.map((slide:SliderType,index:number)=>(
                  <SwiperSlide key={index}>
                    <SwiperHeader 
                      title={slide?.title} 
                      subTitle={slide?.subTitle} 
                      bgSrc={slide?.bgSrc} 
                      videoSrc={slide?.videoSrc} 
                      sliderNumber={`${index<9 ?'0'+(index+1): index+1}`}
                      />
                  </SwiperSlide>
                ))
              }
              {/* <SwiperSlide><SwiperHeader title={`02`}/></SwiperSlide>
              <SwiperSlide><SwiperHeader title={`03`}/></SwiperSlide>
              <SwiperSlide><SwiperHeader title={`04`}/></SwiperSlide> */}
            </Swiper>
            <div className={styles['header__slider-controls-wrapper']}>
              <button type='button' className={styles['header__slider-control-wrapper']} onClick={handlePrev}>
                <LeftArrowTall className={styles['header__slider-control-arrow']}/>
              </button>
              {
                slides && slides?.map((slide:SliderType,index:number)=>(
                  <button type='button' onClick={()=>{changeActiveSlideIndex(index)}}
                  className={`${styles['header__slider-control-wrapper']} 
                  ${activeSlideIndex==index?styles['header__slider-control-wrapper--active']:''}`}>{index<9 ?'0'+(index+1): index+1}</button>
                ))
              }
              {/* <button type='button' onClick={()=>{changeActiveSlideIndex(0)}}
              className={`${styles['header__slider-control-wrapper']} 
              ${activeSlideIndex==0?styles['header__slider-control-wrapper--active']:''}`}>01</button>
              <button type='button'  onClick={()=>{changeActiveSlideIndex(1)}}
              className={`${styles['header__slider-control-wrapper']} 
              ${activeSlideIndex==1?styles['header__slider-control-wrapper--active']:''}`}>02</button>
              <button type='button'  onClick={()=>{changeActiveSlideIndex(2)}}
              className={`${styles['header__slider-control-wrapper']} 
              ${activeSlideIndex==2?styles['header__slider-control-wrapper--active']:''}`}>03</button>
              <button type='button'  onClick={()=>{changeActiveSlideIndex(3)}}
              className={`${styles['header__slider-control-wrapper']} 
              ${activeSlideIndex==3?styles['header__slider-control-wrapper--active']:''}`}>04</button> */}

              <button type='button' className={styles['header__slider-control-wrapper']} onClick={handleNext}>
                <RightArrowTall className={styles['header__slider-control-arrow']}/>
              </button>
            </div>
          {/* </div> */}
        {/* </Container> */}
      </section>
    </>
  )
}

export default Header