import styles from './SwiperBeingInControl.module.css'
import { useTranslation } from 'react-i18next'
import blogCardImage from 'assets/imgs/blogCardImage.jpg'
import {ReactComponent as CalenderIcon} from 'assets/icons/calenderIcon.svg'
import {ReactComponent as LocationIcon} from 'assets/icons/locationIcon.svg'
import { Link } from 'react-router-dom'
type ComponentCustomPropsTypes={
    time:string,
    image:string,
    title:string,
    video:string,
    setActiveVideoSrc:any
}
function SwiperBeingInControl(props:ComponentCustomPropsTypes) {
    const {video,time ,image,title,setActiveVideoSrc}= props
    const {t} =useTranslation()
    
    return (
        <div className={styles['swiper-being-in-control']}>
            <button className={styles['swiper-being-in-control__button']}
            onClick={()=>{setActiveVideoSrc(video)}}></button>
            <div className={styles['swiper-being-in-control__wrapper']}>
                <img src={image} className={styles['swiper-being-in-control__image']} alt='blog image'/>
                <div className={styles['swiper-being-in-control__time']}>
                    {time}
                </div>
            </div>
            <div className={styles['swiper-being-in-control__title-wrapper']}>
                {title}
            </div>
        </div>
    )
}

export default SwiperBeingInControl