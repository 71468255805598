import styles from './BlogCard.module.css'
import { useTranslation } from 'react-i18next'
import blogCardImage from 'assets/imgs/blogCardImage.jpg'
import {ReactComponent as CalenderIcon} from 'assets/icons/calenderIcon.svg'
import {ReactComponent as LocationIcon} from 'assets/icons/locationIcon.svg'
import { Link } from 'react-router-dom'
type ComponentCustomPropsTypes={
    title?:string
}
function BlogCard(props:ComponentCustomPropsTypes) {
    const {title}= props
    const {t} =useTranslation()
    
    return (
        <div className={styles['blog-card']}>
            <Link to='/blog' className={styles['blog-card__link']}></Link>
            <div className={styles['blog-card__wrapper']}>
                <img src={blogCardImage} className={styles['blog-card__image']} alt='blog image'/>
                <div className={styles['blog-card__content-wrapper']}>
                    <h2 className={styles['blog-card__content-title']}>The exasperating experience of being controlled</h2>
                    <div className={styles['blog-card__features-wrapper']}>
                        <div className={styles['blog-card__feature-wrapper']}>
                            <CalenderIcon className={styles['blog-card__feature-icon']}/>
                            <span className={styles['blog-card__feature-text']}>11.05.2018</span>
                        </div>
                        <div className={styles['blog-card__feature-wrapper']}>
                            <LocationIcon className={styles['blog-card__feature-icon']}/>
                            <span className={styles['blog-card__feature-text']}>NATURE, PHOTOGRAPHY</span>
                        </div>
                    </div>
                    <p className={styles['blog-card__description']}>
                    Photography can be a lonely business, but there is no reason why that has to be the case. Of course, there are many that enjoy the solitude. If you’re a photographer who enjoys more of a community ...
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BlogCard