import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './VideoModal.module.css'
import ReactPlayer from 'react-player';
import {ReactComponent as RightArrow} from 'assets/icons/rightArrow.svg'
import HeaderBgVideo1 from 'assets/videos/HeaderBgVideo1.mp4'

function VideoModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className={styles['video-modal__play-control']} onClick={handleShow}>
        <RightArrow className={styles['video-modal__play-control-icon']}/>
      </button>
      <Modal show={show} onHide={handleClose} size='lg' centered>
        <div className={styles['video-modal__video-wrapper']}>
            <ReactPlayer 
              url={HeaderBgVideo1} 
              controls={true}
              playing={true}
              loop={true}
              muted ={true}
              width="100%"
              height="70vh"
              className={styles['video-modal__video']}
              config={{
                  youtube: {
                      playerVars: { showinfo: 1 }
                  },
                  facebook: {
                      appId: '12345'
                  }
              }}
            />
        </div>
      </Modal>
    </>
  );
}

export default VideoModal