import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState } from 'react'
import styles from './AboutUs.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Col, Container, Row } from 'react-bootstrap'
import aboutUsImage from 'assets/imgs/aboutUsImage.png'
import {ReactComponent as AnimationIcon} from 'assets/icons/animationIcon.svg'
import {ReactComponent as EventSeminarIcon} from 'assets/icons/eventSeminarIcon.svg'
import {ReactComponent as PhotographyIcon} from 'assets/icons/photographyIcon.svg'
import {ReactComponent as GraphicDesignIcon} from 'assets/icons/graphicDesignIcon.svg'
import {ReactComponent as SocialVideoAdsIcon} from 'assets/icons/socialVideoAdsIcon.svg'
import {ReactComponent as VideoProductionIcon} from 'assets/icons/videoProductionIcon.svg'
import { emailConnect } from 'utils/features'
import SectionTitle from 'components/Global/Elements/SectionTitle/SectionTitle'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import SwiperAboutUs from 'components/Global/Elements/SwiperAboutUs/SwiperAboutUs'

import aboutUsImage1 from 'assets/imgs/aboutUsImg1.png'
import aboutUsImage2 from 'assets/imgs/aboutUsImg2.png'
import aboutUsImage3 from 'assets/imgs/aboutUsImg3.png'

export type TeamMemberType ={
  image:string,
  title:string,
  description:string,
}
// export TeamMemberType =TeamMemberType
function AboutUs() {
  const {t} =useTranslation()
  const [teamMembers,setTeamMembers] =useState<TeamMemberType[]>([
    {image:aboutUsImage1,title:'Name of Employ',description:'Description on Employ'},
    {image:aboutUsImage2,title:'Name of Employ',description:'Description on Employ'},
    {image:aboutUsImage3,title:'Name of Employ',description:'Description on Employ'},
    {image:aboutUsImage1,title:'Name of Employ',description:'Description on Employ'},
    {image:aboutUsImage2,title:'Name of Employ',description:'Description on Employ'},
    {image:aboutUsImage3,title:'Name of Employ',description:'Description on Employ'},
  ])
  const [isPageLoading,setIsPageLoading]=useState(true)
  return (
    <>
      <section className={styles['about-us']} id='about-us'>
        <SectionTitle title={`${t('ABOUT ME')}`}/>
        <Container>
          <Row>
            <Col lg='7'>
              <div className={styles['about-us__content-wrapper']}>
                {/* <h1 className={styles['about-us__sub-title']}>{t('Hello & Welcome')}</h1> */}
                <h1 className={styles['about-us__title']}>{t('We go beyond the limits of the place to tell stories with an inspiring message to the world.')}</h1>
                <p className={styles['about-us__description']}>
                  Tulpar is a Dubai-based production company
    It aims to produce feature films, documentaries, commercials, promotional films and TV shows.
    We also provide production services to foreign companies shooting and making films in Dubai.
    Our creative team is young professionals who are passionate about creating wonderful and creative stories..
    We produce breathtaking and heart touching videos.
                </p>
                <div className={styles['about-us__features-wrapper']}>
                  <div className={styles['about-us__feature-wrapper']}>
                    <VideoProductionIcon className={styles['about-us__feature-icon']}/>
                    <span className={styles['about-us__feature-text']}>1- {t('Video Production')}</span>
                  </div>
                  <div className={styles['about-us__feature-wrapper']}>
                    <PhotographyIcon className={styles['about-us__feature-icon']}/>
                    <span className={styles['about-us__feature-text']}>2- {t('Creative Photography')}</span>
                  </div>
                  <div className={styles['about-us__feature-wrapper']}>
                    <AnimationIcon className={styles['about-us__feature-icon']}/>
                    <span className={styles['about-us__feature-text']}>3- {t('Animation')}</span>
                  </div>
                  <div className={styles['about-us__feature-wrapper']}>
                    <EventSeminarIcon className={styles['about-us__feature-icon']}/>
                    <span className={styles['about-us__feature-text']}>4- {t('Event and seminar coverage Webcasts')}</span>
                  </div>
                  <div className={styles['about-us__feature-wrapper']}>
                    <GraphicDesignIcon className={styles['about-us__feature-icon']}/>
                    <span className={styles['about-us__feature-text']}>5- {t('Graphic Design')}</span>
                  </div>
                  <div className={styles['about-us__feature-wrapper']}>
                    <SocialVideoAdsIcon className={styles['about-us__feature-icon']}/>
                    <span className={styles['about-us__feature-text']}>6- {t('Social Video Ads')}</span>
                  </div>
                </div>
                <div className={styles['about-us__team-wrapper']}>
                  <Swiper
                    spaceBetween={30}
                    slidesPerView={3}
                    cssMode= {true}
                    // ref={swiperRef}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                        
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay,  Navigation]}
                    className="mySwiper"
                  >
                    {
                      teamMembers && teamMembers?.map((member:TeamMemberType,index:number)=>(
                        <SwiperSlide key={index}>
                          <SwiperAboutUs 
                          image={member?.image}
                          title={member?.title}
                          description={member?.description}/>
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                </div>
              </div>

            </Col>
            <Col lg='5'>
              <img src={aboutUsImage} alt='about us image' className={styles['about-us__img']}/>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default AboutUs