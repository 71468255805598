import axios from 'axios';
// import Cookies from 'js-cookie';
const BASE_URL =`${process.env.REACT_APP_API_KEY}`
// let lang='ar'
// if(Cookies.get('i18next')=='br'){
//     lang='en'
// }
const axiosConfig = axios.create({
    baseURL: BASE_URL,
    // headers: {
    //     "Accept-Language": `${lang}`
    // }
});
// const axiosConfigNoRegister = axios.create({
//     baseURL: BASE_URL,
//     headers: {
//         'Content-Type': 'application/json',
//     }
// });

// axiosConfig.interceptors.response.use(response => {
//     return response;
// }, error => {
//     if (error.response.status === 401) {
//     // localStorage.removeItem('token');
//         Cookies.remove('token')
//         Cookies.remove('permissions')
//         Cookies.remove('user')
//         createBrowserHistory().push('/login');
//         window.location.reload();
//         toast.error('Account Opened From Other Device. Please Login Again')
//     }
//     return error;
//  });
export {axiosConfig };