import { useTranslation } from 'react-i18next'
import {motion} from 'framer-motion'
import { useState } from 'react'
import styles from './ContactUs.module.css'
import Footer from 'components/Global/Layout/Footer/Footer'
import NavBar from 'components/Global/Layout/NavBar/NavBar'
import { Col, Container, Row } from 'react-bootstrap'
import BlogCard from 'components/Global/Elements/Cards/BlogCard/BlogCard'
import {ReactComponent as PhoneIcon} from 'assets/icons/phoneIcon.svg'
import {ReactComponent as MailIcon} from 'assets/icons/mailIcon.svg'
import {ReactComponent as AddressIcon} from 'assets/icons/addressIcon.svg'
import { emailConnect, emailPattern } from 'utils/features'
import SectionTitle from 'components/Global/Elements/SectionTitle/SectionTitle'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
function ContactUs() {
  const {t} =useTranslation()
  const [isSubmitting,setIsSubmitting]=useState<boolean>(false)
  const [name,setName] = useState<string>('')
  const [email,setEmail] = useState<string>('')
  const [message,setMessage] = useState<string>('')
  
  function validateInputs(){
    let status =true
    if(!name){
      toast.error(t('Contact Name Is Required'))
      status=false
    }
    if(!message){
      toast.error(t('Contact Name Is Required'))
      status=false
    }
    if(email&&(!emailPattern.test(email))){
      toast.error(t('Email Must Be Valid Email'))
      status=false
    }
    return status
  }

  function sendMessage(){
    if(!validateInputs()){
      return
    }
    setIsSubmitting(true)
    const formData  = new FormData()
    formData.append('name',name)
    formData.append('email',email)
    formData.append('message',message)

    axiosConfig.post(`/contact-us/create-contact-us`,formData).then(res=>{
        setIsSubmitting(false)
        toast.success(t(`Contact Us Added Successfully`))
      }).catch(err=>{
        setIsSubmitting(false)
        toast.error(t('Something went wrong'))
      })
  }
  return (
    <>
      <section className={styles['contact-us']} id='contact-us'>
        <SectionTitle title={`${t('Contact Us')}`}/>
        <Container>
          <Row>
            <Col lg='7'>
              <div className={styles['contact-us__content-wrapper']}>
                <h1 className={styles['contact-us__title']}>{t('Contact Us')}</h1>
                <div className={styles['contact-us__contacts-wrapper']}>
                
                  <button role='button' onClick={()=>{window.open(`tel:${'+380487055562'}`, '_self')}}  className={styles['contact-us__contact-wrapper']}>
                    <PhoneIcon className={styles['contact-us__contact-icon']}/>
                    <div className={styles['contact-us__contact-info-wrapper']}>
                      <p className={styles['contact-us__contact-info-label']}>{t('Phone')}:</p>
                      <p className={styles['contact-us__contact-info-text']}>+38 048 705 55 62</p>
                    </div>
                  </button>
                  <a href={emailConnect('support@promo-theme.com')}  className={styles['contact-us__contact-wrapper']}>
                    <MailIcon className={styles['contact-us__contact-icon']}/>
                    <div className={styles['contact-us__contact-info-wrapper']}>
                      <p className={styles['contact-us__contact-info-label']}>{t('Email')}:</p>
                      <p className={styles['contact-us__contact-info-text']}>support@promo-theme.com</p>
                    </div>
                  </a>
                  <a href={"https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai"} target='_blank' className={styles['contact-us__contact-wrapper']}>
                    <AddressIcon className={styles['contact-us__contact-icon']}/>
                    <div className={styles['contact-us__contact-info-wrapper']}>
                      <p className={styles['contact-us__contact-info-label']}>{t('Address')}:</p>
                      <p className={styles['contact-us__contact-info-text']}>London, Baker Street 233</p>
                    </div>
                  </a>
                </div>

                <form>
                  <h3 className={styles['contact-us__form-title']}>{t('Mail Me')}</h3>
                  <div className={styles['contact-us__form-inputs-wrapper']}>
                    <div className={styles['contact-us__form-input-wrapper']}>
                      <input 
                        type='text' 
                        value={name}
                        onChange={(e)=>{setName(e.target.value)}}
                        className={styles['contact-us__form-input']} 
                        id='nameInput' 
                        placeholder=' '
                      />
                      <label htmlFor='nameInput' className={styles['contact-us__form-label']}>{t('Your Name')}</label>
                    </div>
                    <div className={styles['contact-us__form-input-wrapper']}>
                      <input 
                        type='email' 
                        value={email}
                        onChange={(e)=>{setEmail(e.target.value)}}
                        className={styles['contact-us__form-input']} 
                        id='emailInput' 
                        placeholder=' '
                      />
                      <label htmlFor='emailInput' className={styles['contact-us__form-label']}>{t('Your E-mail')}</label>
                    </div>
                  </div>
                    <div className={styles['contact-us__form-input-wrapper']}>
                      <input 
                        type='text' 
                        value={message}
                        onChange={(e)=>{setMessage(e.target.value)}}
                        className={styles['contact-us__form-input']} 
                        id='messageInput' 
                        placeholder=' '
                      />
                      <label htmlFor='messageInput' className={styles['contact-us__form-label']}>{t('Your Message')}</label>
                    </div>
                  <button
                  type="button"
                  onClick={sendMessage}
                  disabled={isSubmitting}
                  className={styles['contact-us__form-submit']}>
                    <span className={styles['contact-us__form-submit-text']}>{t('Send')}</span>
                  </button>
                </form>
              </div>
            </Col>
            <Col lg='5'>
              <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.542436314145!2d55.2720317771217!3d25.184923420268365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x82aa2650ea1602f9%3A0xc69edd6fba89ca4e!2zUGF2aWxpb24gVGVjaG5vbG9naWVzIHwg2KjYp9mB2YrZhNmK2YjZhiDYqtmD2YbZiNmE2YjYrNmK!5e0!3m2!1sen!2seg!4v1683549363383!5m2!1sen!2seg" 
              // width="600" 
              // height="450" 
              // style="border:0;" 
              // allowfullscreen=""
              className={styles['contact-us__map']} 
              loading="lazy" 
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ContactUs